import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

export const StyledContactContainer = styled.div`
  font-family: ${props => props.theme.type.fontFamily};
  height: calc(100% - 108px);
  width: calc(100% - ${props => props.theme.layout.gutter * 2}px);
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};

  padding: ${props => props.theme.layout.gutter}px;
  padding-bottom: ${props => props.theme.layout.gutter * 2}px;
  transition: all 0.25s ease-in-out;
`;

export const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: block;
    margin: 12px 0;
    font-size 20px;
  }
`;

export const StyledHeaderSpan = styled.span`
  font-weight: bold;
`;

const contactArray = [
  "bandContact",
  "bookingNorthAmerica",
  "publicityNorthAmerica",
  "dePr",
  "frPr",
];

const contactLocales = {
  bandContact: "General Band Contact",
  bookingNorthAmerica: "Booking North America",
  publicityNorthAmerica: "US & UK Publicity",
  dePr: "DE PR",
  frPr: "FR PR",
};

export const query = graphql`
  query allContentfulContacts {
    allContentfulContacts {
      nodes {
        bandContact
        bookingNorthAmerica
        dePr
        frPr
        publicityNorthAmerica
      }
    }
  }
`;

const Contact = ({ data }) => {
  const {
    allContentfulContacts: { nodes },
  } = data;

  const contactData = nodes[0];

  return (
    <StyledContactContainer>
      <h2>Contact</h2>
      <StyledUl>
        {contactArray.map((item, index) => (
          <li key={`${item}-${index}`}>
            <StyledHeaderSpan>{`${contactLocales[item]}`} </StyledHeaderSpan> --
            {`${contactData[item]}`}
          </li>
        ))}
      </StyledUl>
    </StyledContactContainer>
  );
};
export default Contact;
